import { FC, MouseEvent, UIEvent, useEffect, useState } from 'react';
import Flex from 'antd/lib/flex';
import { useRouter, useSearchParams } from 'next/navigation';

import Select from 'components/atoms/Select';
import ShowElement from 'components/atoms/ShowElement';
import useAuthUser from 'hooks/common/useAuthUser';
import useProjectsInfiniteQuery from 'hooks/apis/queries/useProjectsInfiniteQuery';
import { deleteProjectId, setProjectId } from 'app/actions';
import useProjectByIdQuery from 'hooks/apis/queries/useProjectByIdQuery';
import {
  DividerProjectsSwitcher,
  DropDownItemsLabel,
  ProjectsSwitcherMobileSkeleton,
  ProjectsSwitcherSkeleton,
  ProjectSwitcherMobileWrapper,
  ProjectSwitcherWrapper
} from './styles';
import { IProjectSwitcherProps } from './types';
import BodyText from 'components/atoms/BodyText';
import { colors } from 'styles/variables.module';
import Icon from 'components/atoms/Icon';
import InfiniteScroll from 'components/organisms/InfiniteScroll';
import DropDown from 'components/atoms/DropDown';
import useTranslations from 'hooks/common/useTranslations';
import { queryParamRedirectedFromDashboard } from 'common/constants/common';
import createQueryParam from 'utils/helpers/createQueryParam';

const ProjectSwitcher: FC<IProjectSwitcherProps> = ({
  mobileMode = false,
  isAppSinglePage = false
}) => {
  const { isLoggedChecking, projectId } = useAuthUser();
  const searchParams = useSearchParams();
  const router = useRouter();
  const { t } = useTranslations('common');
  const [isOpenProjectsDropDown, setIsOpenProjectsDropDown] = useState(false);
  const {
    projectById,
    isInitialLoading: isLoadingProjectByIdQuery,
    isError: isErrorProjectById
  } = useProjectByIdQuery(Number(projectId), false);

  const {
    data: projects,
    fetchNextPage: fetchNextPageProjects,
    isFetching: isFetchingProject,
    hasNextPage: hasNextPageProject,
    isInitialLoading
  } = useProjectsInfiniteQuery();

  useEffect(() => {
    if (isErrorProjectById) {
      const removedCookieWhenHaveAError = async () => {
        if (isErrorProjectById) {
          await deleteProjectId();

          if (searchParams.get(queryParamRedirectedFromDashboard)) {
            const deletedProjectIdQueryParam = createQueryParam(
              {
                projectId: null
              },
              'delete'
            );

            router.replace(deletedProjectIdQueryParam, { scroll: false });
          }
        }
      };

      removedCookieWhenHaveAError();
    }
  }, [isErrorProjectById, router, searchParams]);

  const selectedProjectValue =
    projectId && !isErrorProjectById ? Number(projectId) : null;

  const initialLoadingProjects = isLoadingProjectByIdQuery || isInitialLoading;
  const iconDropDownName = isOpenProjectsDropDown ? 'arrowUp' : 'arrowDown';
  const projectSwitcherData = [
    ...(projectById && projectById.id !== 0
      ? [{ label: projectById.name, value: projectById.id }]
      : []),
    ...projects
      .filter(({ id }) => id !== Number(projectId))
      .map(({ id: value, name: label }) => ({
        label,
        value
      }))
  ];

  const onPopUpScrollHandler = async (elem: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = elem.currentTarget;
    const isFetch =
      +scrollTop.toFixed() + +clientHeight.toFixed() >= +scrollHeight.toFixed();

    if (isFetch && hasNextPageProject && !isFetchingProject) {
      await fetchNextPageProjects();
    }
  };

  const onChangeProject = async (projectId: string) => {
    await setProjectId(projectId);
    window.location.reload();
  };

  const onDeleteProjectIdFromCookie = async () => {
    await deleteProjectId();
    window.location.reload();
  };

  const onOpenChangeProjectsHandler = () => {
    setIsOpenProjectsDropDown(prevState => !prevState);
  };

  return (
    <ShowElement isShow={isLoggedChecking}>
      <ShowElement
        isShow={mobileMode}
        show={
          <ProjectSwitcherWrapper>
            <DividerProjectsSwitcher
              type="vertical"
              data-app-single-page={isAppSinglePage}
            />
            <ShowElement
              isShow={!initialLoadingProjects}
              show={<ProjectsSwitcherSkeleton active />}
            >
              <Select
                data={projectSwitcherData}
                loading={initialLoadingProjects}
                onChange={onChangeProject}
                onPopupScroll={onPopUpScrollHandler}
                keyProp="value"
                textProp="label"
                valueProp="value"
                value={selectedProjectValue}
                placeholder={t('selectProject')}
                onClear={onDeleteProjectIdFromCookie}
                allowClear
                style={{
                  width: '231px'
                }}
              />
            </ShowElement>
          </ProjectSwitcherWrapper>
        }
      >
        <ProjectSwitcherMobileWrapper>
          <ShowElement
            isShow={!initialLoadingProjects}
            show={<ProjectsSwitcherMobileSkeleton active />}
          >
            <InfiniteScroll
              next={fetchNextPageProjects}
              loading={isFetchingProject}
              hasMore={Boolean(hasNextPageProject)}
              scrollTarget={`.projectSwitcher`}
            >
              <DropDown
                trigger={['click']}
                open={isOpenProjectsDropDown}
                onOpenChange={onOpenChangeProjectsHandler}
                menu={{
                  items: projectSwitcherData.map(({ label, value: key }) => ({
                    label: (
                      <DropDownItemsLabel>
                        <BodyText
                          text={label}
                          size="small"
                          fontWeight="regular"
                        />
                      </DropDownItemsLabel>
                    ),
                    key
                  })),
                  className: 'projectSwitcher',
                  selectable: true,
                  defaultSelectedKeys: [String(projectId)],
                  onClick: ({ key }) => onChangeProject(key),
                  style: { height: 300, overflowY: 'auto' }
                }}
              >
                <div className="selected-project">
                  <div>
                    <BodyText
                      color={
                        selectedProjectValue ? colors.gray_9 : colors.gray_7
                      }
                      size="base"
                      fontWeight="regular"
                      text={projectById.name || t('selectProject')}
                    />
                  </div>
                  <Flex align="center">
                    <ShowElement
                      isShow={Boolean(projectId)}
                      show={
                        <Icon name={iconDropDownName} width={15} height={8} />
                      }
                    >
                      <Icon
                        name="projectResetIcon"
                        width={25}
                        height={24}
                        onClick={(e: MouseEvent<HTMLImageElement>) => {
                          e.stopPropagation();
                          onDeleteProjectIdFromCookie();
                        }}
                      />
                    </ShowElement>
                  </Flex>
                </div>
              </DropDown>
            </InfiniteScroll>
          </ShowElement>
        </ProjectSwitcherMobileWrapper>
      </ShowElement>
    </ShowElement>
  );
};

export default ProjectSwitcher;
