'use client';

import { FC } from 'react';
import Space from 'antd/lib/space';
import { useSelectedLayoutSegments } from 'next/navigation';
import { LeftOutlined } from '@ant-design/icons';
import Grid from 'antd/lib/grid';

import {
  BackButtonWrapper,
  HeaderStyle,
  HeaderWrapper,
  LogoStyleWrapper,
  RightNavigationStyleWrapper
} from 'components/organisms/MainHeader/styles';
import AppStoreLogo from 'components/molecules/AppStoreLogo';
import LoginOrSignUp from 'components/molecules/LoginOrSignUp';
import LoggedUserInfo from 'components/molecules/LoggedUserInfo';
import Icon from 'components/atoms/Icon';
import ShowElement from 'components/atoms/ShowElement';
import Button from 'components/atoms/Button';
import { Routes } from 'common/constants/common';
import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import { IMainHeaderProps } from './types';
import ProjectSwitcher from 'components/molecules/ProjectSwitcher';
import useScrollNavigation from 'hooks/common/useScrollNavigation';

const { useBreakpoint } = Grid;

const MainHeader: FC<IMainHeaderProps> = ({
  isMainPage,
  isItCheckoutPage,
  isLoggedUser,
  user,
  onLogout,
  onLogIn,
  navigateToDashboardOrAccounts
}) => {
  const { push } = useRouteWithLanguage();
  const segments = useSelectedLayoutSegments();
  const { xs } = useBreakpoint();
  const userName = user?.fullName || '';
  const avatarUrl = user?.avatarUrl || null;
  const isAppSinglePage = segments.length === 1 && segments[0] !== 'search';
  const { isVisible: isVisibleProjectSwitcher } = useScrollNavigation();
  const isShowMobileProjectSwitcher =
    isVisibleProjectSwitcher && !isItCheckoutPage && Boolean(xs);

  const isShowWebProjectSwitcherForSinglePage =
    !isAppSinglePage && !isItCheckoutPage;

  const goToSearchPage = () => {
    const isItCheckoutPage = segments.includes('checkout');

    if (isItCheckoutPage) {
      return (window.location.href = Routes.SEARCH.pathname);
    }

    return push(Routes.SEARCH.pathname);
  };

  const goToMainPage = () => {
    return push(Routes.HOME.pathname);
  };

  return (
    <HeaderWrapper>
      <HeaderStyle data-main={isMainPage}>
        <ShowElement isShow={isAppSinglePage}>
          <BackButtonWrapper>
            <Button
              shape="circle"
              type="text"
              size="large"
              icon={<LeftOutlined />}
              onClick={goToMainPage}
            />
            <ShowElement isShow={!isItCheckoutPage}>
              <ProjectSwitcher isAppSinglePage />
            </ShowElement>
          </BackButtonWrapper>
        </ShowElement>

        <LogoStyleWrapper data-single-page={isAppSinglePage}>
          <AppStoreLogo />
          <ShowElement isShow={isShowWebProjectSwitcherForSinglePage}>
            <ProjectSwitcher />
          </ShowElement>
        </LogoStyleWrapper>

        <RightNavigationStyleWrapper>
          <Space>
            <Icon
              onClick={goToSearchPage}
              name="search"
              className="mobileLoginAndSearchIcons"
              width={24}
              height={24}
            />
          </Space>
          <ShowElement
            isShow={isLoggedUser}
            show={
              <div className="LoginOrSignUp-holder">
                <LoginOrSignUp onLogIn={onLogIn} />
              </div>
            }
          >
            <LoggedUserInfo
              userName={userName}
              avatarUrl={avatarUrl}
              onLogout={onLogout}
              navigateToDashboardOrAccounts={navigateToDashboardOrAccounts}
            />
          </ShowElement>
        </RightNavigationStyleWrapper>
      </HeaderStyle>
      <ShowElement isShow={isShowMobileProjectSwitcher}>
        <ProjectSwitcher mobileMode />
      </ShowElement>
    </HeaderWrapper>
  );
};

export default MainHeader;
