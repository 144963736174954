import { EnvConfig } from 'configs/env';
import {
  returnUrlQueryParam,
  targetName
} from 'common/constants/authorization';

const redirectHandlerWithSso = (
  target: 'app-store' | 'dashboard' | 'blank' | 'accounts',
  returnUrl: string
) => {
  const encodedReturnUrl = encodeURIComponent(returnUrl);

  return (window.location.href = `${EnvConfig.accountsBackend}/callback?${targetName}=${target}&${returnUrlQueryParam}=${encodedReturnUrl}`);
};

export default redirectHandlerWithSso;
